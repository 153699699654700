.vehicleInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.vehicleInfoList {
    margin-bottom: 20px;
}

.vehicleType {
    color: gray;
    font-size: 12px
}

.vehicleSpeed {
    color: #32db29;
    font-weight: 700;
}

.driverInfoString {
    color: gray;
    font-size: 12px;
    max-width: 85%;
}

.liveTrackingDrawerDonuts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.liveTrackingDrawerDonutsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
}

.liveTrackingDrawerDonutsTitle {
    font-size: 12px;
}

.liveTrackingDrawerDonutsTime {
    font-size: 13px;
    color: darkgrey;
}

.liveTrackingDrawerDonutsDivider {
    display: block;
    height: 90%;
    height: 20px;
    width: 10px;
    background: #9b9b9b;
    margin: 20px 5px 20px 5px;
}

.liveTrackingDrawerHOS {
    margin: 20px 0px;
}

.liveTrackingDrawerHOS a {
    text-decoration: none;
}

.liveTrackingDrawerHOSBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    background: #e0554b;
    margin: 10px 0px;
    cursor: pointer;
}

.liveTrackingDrawerHOSBtn:hover {
    background: #bb463d;
}

.liveTrackingDrawerHOSBtnTitle {
    font-size: 12px;
    font-weight: bold;
}

.liveTrackingDrawerHOSBtnTime {
    font-size: 14px;
}

.liveTrackingDrawerDiagnostics {
display: flex;
flex-direction: column;
margin-top: 10px;
}

.liveTrackingDrawerDiagnosticsItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: #bdbdbd solid 1px;
    padding-bottom: 3px;
}

.liveTrackingDrawerDiagnosticsTitle{
    font-weight: bold;
    font-size: 12px;
}

.liveTrackingDrawerDiagnosticsStatus{
    font-size: 12px;
}