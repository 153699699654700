html {
  box-sizing: border-box;
  font-size: 16px;
  margin: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
  height: 100%;
  /* overflow-x: hidden; */
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #543400;
  background-image: url(./../public/images/background2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

/* Login page styles */

.loginForm-wrapper {
  padding-top: 60px;
}

.linkLogin-container {
  position: absolute;
  top: 30px;
  right: 30px;
  font-weight: bold;
  font-size: 14px;
}

.linkLogin {
  color: #fff;
}


.linkicon {
  margin-right: 10px;
}

.loginLink {
  font-weight: 800;
  color: #0009e6;
}

.loginTitle {
  margin-bottom: 30px;
}

.loginForm>*:not(:last-child) {
  margin-bottom: 15px;
}

.loginFirstColumn {
  padding-right: 20px;
  border-right: solid 1px #cdcccc;
}

@media (max-width: 767px) {
  .loginFirstColumn {
    padding-right: initial;
    border-right: none;
  }
}

.loginSecondColumn {
  padding-left: 20px;
}

@media (max-width: 767px) {
  .loginSecondColumn {
    padding-left: initial;
  }
}

.signupRow>* {
  margin-bottom: 15px;
}

/* END of Login page styles */

/* Dashboard page styles */
.dashboardContainer {
  display: flex;
  min-height: 100vh;
}

/* Sidebar styles */
.sidebar {
  min-width: 260px;
  background-color: #262626;
}

.sidebar>* {
  border-bottom: #000000 solid 0.5px;
}

.rightBlock {
  background-color: #fff;
}

.sidebarLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 200px;
  background: #fff;
  border-radius: 25px;
  margin: 10px;
}

.sidebarLogoImage {
  display: block;
  max-height: 80%;
  max-width: 80%;
}

.sidebarNavLink {
  color: #fff;
  font-size: 23px;
  font-weight: medium;
  padding: 12px 0 12px 30px;
}

.sidebarNavLink:hover {
  background: #464646;
}

.activeIcon {
  color: #f97316;
  background-color: #4e2f00;
  border-bottom: #f97316 solid 1px;
}

.mantine-dstkpx {
  max-width: 160px;
}

.sidebarNavSubLink {
  margin-left: 25px;
}

.sidebarFooter {
  display: block;

  background: #000;
}

/* End of Sidebar styles */
/* Start of Dashboard styles */
.rightBlock {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Start of Dashboard header styles */

.tableContainer {
  padding: 20px;
}

.btnLogs,
.datePicker,
.driverSelect {
  margin: 10px;
}

.btnLogs {
  align-self: flex-end;
}

.btnLogs:hover {
  background-color: #f4a93f;
}


.btnPdf {
  background-color: #F43F5E;
}

.btnExcell {
  background-color: #10B981;
}

.btnRecords {
  background-color: #2563EB;
}

.btnPrint {
  background-color: #4ADE80;
}

.btnSendFile .btnSendEmail {
  background-color: #38BDF8;
}


/* End of Dashboard header styles */
.livetrackerContainer {
  display: block;
  overflow-x: hidden;
}

.mapTableContainer {
  display: block;
  position: absolute;
  top: 55px;
  right: 0px;
  transition: all 0.5s ease-out;
}

.mapPaper {
  flex: 1;
  height: 100vh;
  z-index: 0;
}

.statusCell {
  text-align: center;
}


/* Driverlog styles */
.driverLogBtns {
  padding: 20px;
}

.driverLogBtnsBlock {
  display: flex;
  justify-content: center;
}

.chart {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap
}

.driverLogDonuts {
  padding: 15px;
}

.apexContainer {
  padding: 15px;
  flex-grow: 1;
}

.arrow_box {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  border: 1px solid #626262;
  border-radius: 5px;
  background-color: #4e4e4e;
  padding: 0px 5px;
  line-height: 0;
  padding: 8px;
  margin: 0;
}

.arrow_box_time {
  display: flex;
  flex-direction: row;
  padding: 5px 0 0 0;
}

.arrow_box_time_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow_box_time_divider {
  display: block;
  height: 2px;
  width: 5px;
  background: #fff;
  margin: 20px 5px 20px 5px;
}

.arrow_box_time_item_time {
  font-weight: 800;
}

.arrow_box_footer {
  display: flex;
  flex-direction: row;
  margin: 0;

}

.arrow_box_round {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #000;
  margin: -8px 12px 0 0;
}

.arrow_box_round_off {
  background-color: #000;
}

.arrow_box_round_on {
  background-color: #4392dd;
}

.arrow_box_round_drv {
  background-color: #03940f;
}

.arrow_box_round_sb {
  background-color: #dbb03a;
}

.driverLogTimeChart {
  display: flex;
  flex-direction: row;
}

.driverLogHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  flex-wrap: wrap;
}

.driverLogHeaderItem {
  align-self: center;
}

.driverLogHeaderInfo {
  display: flex;
}

.driverLogHeaderInfoItem {
  margin-right: 50px;
  line-height: 0;
}

.driverLogTitle {
  margin-top: 15px;
  padding-bottom: 10px;
}

.driverLogList {
  text-align: left;
  line-height: 22px;
  font-size: 13px;
}

.driverLogDatePicker {
  display: flex;
  align-items: flex-end;
  padding: 20px 40px;
  border-radius: 10px;
  background: #7fb9f7;
  /* border: 1px solid #e9e9e9;
  -webkit-box-shadow: 8px 7px 23px -12px rgba(160, 162, 184, 1);
  -moz-box-shadow: 8px 7px 23px -12px rgba(160, 162, 184, 1);
  box-shadow: 8px 7px 23px -12px rgba(160, 162, 184, 1); */
}

.driverLogDatePickerItem {
  margin: 0 20px;
}

.driverLogTimers {
  display: flex;
  flex: 2;
  border: solid 1px #e7e7e7;
  background: #f8f6f6;
  border-radius: 10px;
  margin: 0 10px;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1211px) {
    padding: 0px;
  }
}

.driverLogTimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  margin: 15px;
  background: #eb6d6d;
  border-radius: 10px;
  color: #fff;
  flex-wrap: wrap;
}

.driverLogTimerTitle {
  font-size: 15px;
  font-weight: bold;
}

.driverLogTimeChartContainer {
  display: flex;
  flex-direction: row;
}

.driverLogTimeChartItem:first-child {
  flex-grow: 1;
  flex-shrink: 0;
}

.driverLogTimeChartItem:nth-child(2) {
  display: flex;
}

.driverLogTimeChartDaily {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  margin: 30px 0 50px 0;
}

.driverLogTimeChartDaily:nth-child(2) {
  flex-grow: 1;
}

.driverLogLegend {
  text-align: center;
}

.driverLogTimeChartBtns {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.driverLogTimeChartBtnDivider {
  display: flex;
  height: 25px;
  width: 1px;
  background: #adadad;
  align-self: flex-end;
  margin: 6px 20px;
}

.driverLogTimeChartBtnsRight {
  display: flex;
  justify-self: space-between;
  align-items: flex-end;
}

.driverLogTimeChartBtn {
  margin: 0 16px;
}

.driverLogTimeChartBtnDate::before {
  position: absolute;
  top: -15px;
  left: 0;
  display: block;
  height: 15px;
  width: 20px;
  content: 'adf';
}

.legendContainer {
  display: flex;
  font-size: 12px;
  justify-content: center;
}

.legendItem {
  color: #fff;
  padding: 5px;
  background: #000;
  border-radius: 15px;
  margin: 0 20px;
}

.driverLogActionBtns {
  border-radius: 15px;
  background: #e2e2e2;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  margin: 0 150px;
}

.driverLogActionBtn {
  margin: 15px;
}

.driverLogTable {
  margin: 30px 20px;
}

.driverLogInfoAccordion {
  margin: 0 50px 50px 50px;
}

.driverLogInfo-container {
  display: flex;
  padding: 0px 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.driverLogInfo {
  display: flex;
  justify-content: flex-start;
}

.driverLogInfo-column {
  padding-right: 10px;
}

.driverLogInfo-title {
  display: inline-block;
}

.driverLogInfo-name {
  background: #929292;
  color: #101a2e;
  font-weight: 700;
  padding: 10px;
  border-bottom: solid 1px #fff;
}

.driverLogInfo-item {
  padding: 10px;
  border-bottom: solid 1px #929292;
}

.table-container {
  display: flex;
  flex-direction: column;
}

.table-header {
  display: flex;
  min-width: 100px;
}

.row3 {
  display: flex;
  padding: 10px;
  flex-direction: row;
  border-bottom: solid 1px #929292;
}

.table-container th:nth-child(1),
.table-container td:nth-child(1) {
  width: 200px;
}

.table-container th:nth-child(2),
.table-container td:nth-child(2) {
  width: 150px;
}

.table-container th:nth-child(3),
.table-container td:nth-child(3) {
  width: 150px;
}

.driverLogSignature {
  display: block;
  height: 100px;
  background: no-repeat center url('../public/images/signatureExample.jpeg');
}

.inspectionLogContainer {
  display: flex;
  justify-content: center;
}

.inspectionLogTransferList {
  background: #e2e2e2;
  border-radius: 20px;
  padding: 20px;
  margin: 30px;
  display: flex;

}

/* Administration styles */
/* Companies styles */
.companies-table {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  -webkit-box-shadow: 10px 4px 18px -1px rgba(176, 176, 176, 1);
  -moz-box-shadow: 10px 4px 18px -1px rgba(176, 176, 176, 1);
  box-shadow: 10px 4px 18px -1px rgba(176, 176, 176, 1);
}

.companies-title {
  text-align: center;
}

/* Vehicles styles */
.vehicles-map {
  height: 400px;
  width: 500px;
}

.vehicles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.vehicles-table {
  padding: 20px;
}

.vehicles-map {
  border-radius: 8px;
  padding: 20px;
}

.vehicle-next-service-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  background: #73ee71;
  border: 2px solid #1eb119;
}

/* Native leaflet styles rewrited */
.leaflet-div-icon {
  background: none;
  border: none;
}

/* Native leaflet styles rewrited */

.welcome {
  width: 100%;
  height: 100%;
  background-image: url(./../public/images/mapbg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.welcomeBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: 50px;
}

.welcomePaper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.welcomeBtn {
  margin: 25px;
}

.errorPage {
  display: flex;
  background-color: #a7cffd;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}